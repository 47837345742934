<template>
  <div class="w-full h-full py-1 px-4 flex items-center">
    <date-picker
      v-model="dateValue"
      format="DD-MM-YYYY"
      value-type="YYYY-MM-DD"
      type="date"
      placeholder="choisir une date"
      input-class="mx-input text-center"
      :shortcuts="datePickerShortcuts()"
      :disabled-date="disallowDays"
      :clearable="false"
      @change="handleDateChange"
    />
    <div class="w-full h-full py-1 px-4 flex items-center justify-between">
      <div class="">
        <router-link
          :to="{ name: 'OpeningConfiguration' }"
          tag="div"
          active-class="bg-blue-400"
          class="
            flex items-center gap-2
        ml-4 px-3 py-2 rounded-md text-center text-sm font-medium
        hover:bg-blue-400 text-gray-200 hover:text-white cursor-pointer"
        >
          <ArrowLeftIcon class="h-4" />
          Paramétrage du rayon d'ouverture
        </router-link>
      </div>

      <div class="flex-1" />

      <router-link
        :to="navigateTo('PreparateurPage')"
        tag="div"
        active-class="bg-blue-400"
        class="
        ml-4 px-3 py-2 rounded-md text-center text-sm font-medium
        hover:bg-blue-400 text-gray-200 hover:text-white cursor-pointer"
      >
        Préparateur
      </router-link>

      <router-link
        :to="navigateTo('BoulangerPage')"
        tag="div"
        active-class="bg-blue-400"
        class="
        ml-4 px-3 py-2 rounded-md text-center text-sm font-medium
        hover:bg-blue-400 text-gray-200 hover:text-white cursor-pointer"
      >
        Boulanger
      </router-link>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/fr';
import dayjs from 'dayjs';
import { ArrowLeftIcon } from '@vue-hero-icons/outline';

export default {
  components: { DatePicker, ArrowLeftIcon },
  data() {
    return {
      dateValue: null,
    };
  },
  mounted() {
    const urlDate = this.$route.query.date;
    const today = dayjs().format('YYYY-MM-DD');
    const isUrlDateToFar = dayjs(urlDate).isAfter(dayjs().add(6, 'days'));

    this.dateValue = urlDate;
    if (!urlDate) {
      this.dateValue = today;
      this.handleDateChange(today);
    }
    if (urlDate && isUrlDateToFar) {
      this.dateValue = today;
      this.handleDateChange(today);
    }
    this.$store.dispatch('setSelectDate', this.dateValue);
  },
  methods: {
    handleDateChange(date) {
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, date },
      });
      this.$store.dispatch('setSelectDate', this.dateValue);
    },
    disallowDays(date) {
      const maxDate = dayjs().add(6, 'days');
      return dayjs(date).isAfter(maxDate);
    },
    navigateTo(routeName) {
      const query = {};
      if (this.$route.query.date) {
        query.date = this.$route.query.date;
      }
      return {
        name: routeName,
        query,
      };
    },
    datePickerShortcuts() {
      return [
        { text: 'Aujourd\'hui', onClick: () => dayjs().toDate() },
        { text: 'Demain', onClick: () => dayjs().add(1, 'days').toDate() },
        { text: 'j +2', onClick: () => dayjs().add(2, 'days').toDate() },
        { text: 'j +3', onClick: () => dayjs().add(3, 'days').toDate() },
        { text: 'j +4', onClick: () => dayjs().add(4, 'days').toDate() },
        { text: 'j +5', onClick: () => dayjs().add(5, 'days').toDate() },
        { text: 'j +6', onClick: () => dayjs().add(6, 'days').toDate() },
      ];
    },
  },
};
</script>
