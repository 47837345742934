<template>
  <div class="relative flex flex-col w-screen h-screen min-h-screen max-h-screen">
    <div class="flex-initial w-full h-16 bg-blue-500">
      <HorizontalMenu />
    </div>
    <div class="flex-1 py-2 overflow-hidden">
      <router-view />
    </div>
  </div>
</template>

<script>
import HorizontalMenu from './HorizontalMenu.vue';

export default {
  components: { HorizontalMenu },
};
</script>

<style>
html, body {
  background: #edf1f8;
}

</style>
